import React from 'react';
import LightSpeed from 'react-reveal/LightSpeed';

import './style.scss';

import Number1 from 'media/Numbers/Number1.inline.svg';
import Number2 from 'media/Numbers/Number2.inline.svg';
import Number3 from 'media/Numbers/Number3.inline.svg';

export function HomeSellPoints(){
    return (
        <>
            <h2>What you won't find elsewhere</h2>

            <div id='HSPPoints'>
                <LightSpeed big left duration={600}>
                    <div className='HSPPoint'>
                        <Number1 alt='Number 1'/>
                        <div>
                            <h3>Charged by use, not number of users</h3>
                            <p>Charging per user leads to password sharing and barriers to everyone accessing the data they need. Instead we charge based on a range of metrics that capture how much the account is being used.</p>
                            <p className='paraSpaceTop'>Invite everyone. If they don’t use it, it won’t cost you anything.</p>
                        </div>
                    </div>
                </LightSpeed>
                <LightSpeed big left duration={600} delay={600}>
                    <div className='HSPPoint'>
                        <Number2 alt='Number 2'/>
                        <div>
                            <h3>Built for one, given to all</h3>
                            <p>Mashoom has been built by meeting the needs of genuine use cases. Every module has been made in close collaboration with a professional partner that uses the solution themselves.</p>
                            <p className='paraSpaceTop'>Every feature has been tested on the ground and finely tuned to make it as awesome as possible. You can benefit without any of the hard work!</p>
                        </div>
                    </div>
                </LightSpeed>
                <LightSpeed big left duration={600} delay={1200}>
                    <div className='HSPPoint'>
                        <Number3 alt='Number 3'/>
                        <div>
                            <h3>No funny business</h3>
                            <p>We are here to provide you data solutions that are transparent, reliable and straightforward; these things shouldn't be complicated.</p>
                            <p className='paraSpaceTop'>We never share data without your consent. We go as far to say as we only do what you are expecting us to do.</p>
                        </div>
                    </div>
                </LightSpeed>
            </div>
        </>
    );
}