import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import { SIGNUP_URL } from 'library/constants.js';

import style from './style.module.scss';

import CallsToAction from 'components/CallsToAction';
import IndyLink from 'components/IndyLink';

export function SBStudentTeams({backgroundImage}){
    const CTATypes = ['SignUp', 'LearnMore'];
    
    return (
        <BackgroundImage className={style.wrap} fluid={backgroundImage.childImageSharp.fluid}>
            <h1 className={style.title}>Are you an engineering student?</h1>
            <div className={style.content}>
                <div className={style.contentLeft + " " + style.contSect + " " + style.big}>
                    <h2>What we do</h2>
                    <ul>
                        <li>Store and organize all your CAD, drawings and other data in one place</li>
                        <li>Works with any CAD package</li>
                        <li>Simple part numbering and drawing authorization</li>
                        <li>Completely online; your IT department doesn't need to be involved</li>
                    </ul>
                </div>
                <div className={style.contentLeft + " " + style.contSect + " " + style.small}>
                    <p>Our online platform provides a simple way to manage your CAD, drawings and other data.</p>
                </div>
                <div className={style.contentRight}>
                    <div className={style.contSect}>
                        <h2>For you, it's free!</h2>
                        <p>You can <IndyLink adr={SIGNUP_URL}>sign-up now for free</IndyLink>, but please <IndyLink adr="/about/#ContactUs">get in contact</IndyLink> within a month to let us know you are a student team.</p>
                    </div>
                    <CallsToAction contClassName={style.CTAs} types={CTATypes} />
                </div>
            </div>
        </BackgroundImage>
    );
}