import React from 'react';

import style from './style.module.scss';
import Utils from 'library/Utils';

class SlideBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            sliding: false,
            slideNum: 0,
            nextSlide: 1,
            paused: false
        }

        this.numSlides = React.Children.count(this.props.children);

        this.bannerHeight = 29;
        this.slideSpeed = 0.8; //s
        this.slideWait = 3; //s

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.pauseToggle = this.pauseToggle.bind(this);
        this.slide = this.slide.bind(this);

        this.sliderMinWidthEM = 50; // ~800px
    }

    componentDidMount() {
        this.start();
    }
    componentWillUnmount() {
        clearTimeout(this.intervalID);
        clearInterval(this.slideTimer);
        clearTimeout(this.slideTimerEnder);
        clearInterval(this.timerID);
    }

    start(){
        if (typeof window === `undefined` || this.numSlides === 1) {
            return;
        }

        this.setState({
            paused: false
        });

        // Setup timer
        clearInterval(this.timerID);
        if(document.readyState === 'complete') {
            this.timerID = setInterval(
                () => this.slide(),
                (this.slideSpeed + this.slideWait) * 1000
            );
        }else{
            window.addEventListener('load', function(){
                this.timerID = setInterval(
                    () => this.slide(),
                    (this.slideSpeed + this.slideWait) * 1000
                );
            }.bind(this));
        }
    }
    stop(){
        this.setState({
            paused: true
        });
    }

    pauseToggle(){
        if (this.state.paused){
            this.start();
        }else{
            this.stop();
        }
    }

    moveToInd(ind){
        if (ind === "NEXT"){
            if (this.state.slideNum + 1 == this.numSlides){
                ind = 0;
            }else{
                ind = this.state.slideNum + 1;
            }  
        }

        if (this.state.sliding){
            return;
        }
        
        this.setState({
            sliding: true,
            nextSlide: ind
        });

        clearTimeout(this.intervalID);
        this.start();
        this.intervalID = setTimeout(function(){
            let nextInd;
            if (ind + 1 == this.numSlides){
                nextInd = 0;
            }else{
                nextInd = ind + 1;
            }

            this.setState({
                sliding: false,
                slideNum: ind,
                nextSlide: nextInd
            });
            this.stop();
        }.bind(this),
            this.slideSpeed * 1000
        );
    }

    slide(){
        if (this.state.paused){
            return;
        }

        this.setState({
            sliding: true
        });

        this.intervalID = setTimeout(function(){
            let nextInd;
            if (this.state.nextSlide + 1 == this.numSlides){
                nextInd = 0;
            }else{
                nextInd = this.state.nextSlide + 1;
            }

            this.setState({
                sliding: false,
                slideNum: this.state.nextSlide,
                nextSlide: nextInd
            });

        }.bind(this),
            this.slideSpeed * 1000
        );
    }

    render() {
        if (typeof window === `undefined`) {
            return(<></>);
        }

        // Case for if there is only one slide
        if (this.numSlides === 1){
            return (
                <div className={style.container}>
                    {React.Children.toArray(this.props.children)[0]}
                </div>
            );
        }

        if (Utils.getScreenWidthEM() > this.sliderMinWidthEM){
            /// Wide screen vertical/automatic slider
            var slideDots = [];
            for (let slideI = 0; slideI < this.numSlides; slideI++) {
                let classStr = style.navPoint;
                if (slideI === this.state.slideNum){
                    classStr = classStr + " " + style.active;
                }

                slideDots.push(
                    <span key={slideI} className={classStr} onClick={this.moveToInd.bind(this, slideI)} />
                );
            }        

            let slideContStyle = {};
            let sliderClass = style.slider;
            if (this.state.sliding){
                slideContStyle.transform = "translateY(" + (-this.bannerHeight) + "em)";
            }else{
                sliderClass = sliderClass + " " + style.disableAni
            }

            return(
                <div className={style.container} onMouseEnter={this.stop} onMouseLeave={this.start}>
                    <div className={sliderClass} style={slideContStyle}>
                        <div className={style.slideContainer} key={this.state.slideNum}>
                            {React.Children.toArray(this.props.children)[this.state.slideNum]}
                        </div>
                        {typeof window !== `undefined` && 
                            <div className={style.slideContainer} key={this.state.nextSlide}>
                                {React.Children.toArray(this.props.children)[this.state.nextSlide]}
                            </div>
                        }
                    </div>
                    <div className={style.navWrap}>
                        <div className={style.nav}>
                            {slideDots}
                        </div>
                    </div>
                </div>
            );

        }else{
            let slideContStyle = {};
            let sliderClass = style.sliderSmall;
            if (this.state.sliding){
                slideContStyle.transform = "translateX(" + (-Utils.getScreenWidthEM()) + "em)";
            }else{
                sliderClass = sliderClass + " " + style.disableAni
            }

            /// Small screen horizontal semi-manual slider
            return(<>
                <div className={style.container} >
                    <div className={sliderClass} style={slideContStyle}>
                        <div className={style.slideContainerSmall} key={this.state.slideNum}>
                            {React.Children.toArray(this.props.children)[this.state.slideNum]}
                        </div>
                        <div className={style.slideContainerSmall} key={this.state.nextSlide}>
                            {React.Children.toArray(this.props.children)[this.state.nextSlide]}
                        </div>
                    </div>
                </div>
                <div className={style.navWrapSmall}>
                    <div className={style.navSmall}>
                        <span onClick={this.pauseToggle}>
                            {this.state.paused ?(
                                "Start"
                            ) : (
                                "Stop"
                            )}
                        </span>
                        <i className="fa fa-arrow-right" aria-hidden="true" onClick={this.moveToInd.bind(this, "NEXT")}/>
                    </div>
                </div>
            </>);
        }
    }
}
 
export default SlideBanner;