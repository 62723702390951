import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import './style.scss';

import PictureSlider from 'components/PictureSlider';
import CallsToAction from 'components/CallsToAction';

import tableBackgroundTile from 'media/components/MainInfo/TableTile.png';
import computer from 'media/components/MainInfo/PC.png';
import PriceLabel from 'components/PriceLabel';

export default function HomeMainInfo() {
    const CTATypes = ['SignUp', 'LearnMore'];
    return (
        <div id='HomeMainInfo' style={{ backgroundImage: "url(" + tableBackgroundTile + ")" }}>
            <div id='HMIContWrap'>
                <Fade bottom duration={1600}>
                    <h1>The Business Solutions Platform</h1>
                </Fade>
                <Zoom bottom cascade duration={1600} delay={600}>
                    <div id='HMIBingWords'>
                        <h3>File sharing</h3>
                        <h3>Data storage</h3>
                        <h3>Form filling</h3>
                        <h3>Data analytics</h3>
                        <h3>Time / location booking</h3>
                        <h3>Design control (PLM)</h3>
                        <h3>Asset tracking</h3>
                        <h3 class='yourIdeaBW'>Your need</h3>
                    </div>
                </Zoom>
                <div id='HMISplit'>
                    <div id='HMILftText'>
                        <h2>Solutions designed by our users</h2>

                        <div id='HMILftText_Center'>
                            <h3>
                                <span>
                                    Pay per use.
                                </span>
                                <span>
                                    Unlimited users.
                                </span>
                                <span>
                                    No installation.
                                </span>
                            </h3>

                            <p>
                                <strong>
                                    <span>
                                        Free if you use under
                                    </span>
                                    <span>
                                        <PriceLabel chargeKey="payThresh" includeTax={false} />
                                    </span>
                                </strong>
                            </p>
                        </div>

                        <CallsToAction contClassName="MainInfoCTA" types={CTATypes}></CallsToAction>
                    </div>
                    <div id='screenshotComp'>
                        <img src={computer} alt="Computer screen"></img>
                        <div className='slideContainerPos'>
                            <PictureSlider
                                pictureManifest={getImageManifest()}
                                pictureSizeManifest={[
                                    {
                                        minScreenWidth: 30,
                                        picWidth: 27, // 432px
                                        picHeight: 15.85, // 254px
                                        maskHeight: 15,
                                        imageKey: "largeImage"
                                    },
                                    {
                                        maxScreenWidth: 30,
                                        picWidth: 17.1, // 274px
                                        picHeight: 10.2, // 163px
                                        maskHeight: 9.4,
                                        imageKey: "smallImage"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getImageManifest() {
    return useStaticQuery(graphql`
        query Screenshots {
            allScreenshotsJson {
                nodes {
                    caption
                    largeImage: image {
                        childImageSharp {
                            fixed(width: 432, height: 254, fit: COVER, cropFocus: NORTHWEST) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    smallImage: image {
                        childImageSharp {
                            fixed(width: 274, height: 163, fit: COVER, cropFocus: NORTHWEST) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            }
        }
    `).allScreenshotsJson.nodes;
}