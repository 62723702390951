import React from 'react';
import Img from "gatsby-image";

import Utils from 'library/Utils';

import './style.scss';

class PictureSlider extends React.Component{
    constructor(props) {
        super(props);

        if (!this.props.pictureManifest || this.props.pictureManifest.length === 0){
            throw new Error('Picture manifest required');
        }

        // In em
        if (!this.props.pictureSizeManifest || this.props.pictureSizeManifest === 0){
            throw new Error('Picture size manifest required');
        }

        this.pictureManifest = Utils.shuffle(this.props.pictureManifest);

        this.aniSpeed = 0.5 //s
        this.changeSpeed = 2 //s
        this.numImages = this.props.pictureManifest.length;

        this.prepNextStage = 0;
        this.lastSlideTime = Date.now();
        this.isPicLoading = false;

        this.state = { 
            translate: 0,
            picInd: 0,
            disableAni: false
        }

        this.slideImage = this.slideImage.bind(this);
        this.prepareNext = this.prepareNext.bind(this);
        this.nextPicLoaded = this.nextPicLoaded.bind(this);
    }

    componentDidMount() {
        // Setup timer
        if(document.readyState === 'complete') {
            this.timerID = setTimeout(
                () => this.slideImage(),
                this.changeSpeed * 1000
            );
        }else{
            window.addEventListener('load', function(){
                this.timerID = setTimeout(
                    () => this.slideImage(),
                    this.changeSpeed * 1000
                );
            }.bind(this));
        }
    }
  
    componentWillUnmount() {
        clearInterval(this.timerID);
        clearInterval(this.timeOutID);
    }

    slideImage() {
        this.lastSlideTime = Date.now();

        this.setState({
            disableAni: false,
            translate: true
        });

        this.timeOutID = setTimeout(
            () => this.prepareNext(),
            this.aniSpeed * 1000
        );
    }

    prepareNext(stage = 1){
        switch (stage) {
            case 1:
                // Disable animation
                this.prepNextStage = stage;

                this.setState({
                    disableAni: true
                });
                break;
        
            case 2:
                // Move to next picture
                this.prepNextStage = stage;

                let picInd = this.state.picInd + 1;
                if (picInd === this.numImages){
                    picInd = 0;
                }

                this.isPicLoading = true;

                this.setState({
                    picInd: picInd,
                    translate: false
                });
                break;

            case 3:
                this.prepNextStage = 0;
                
                let timeRemaining = (this.lastSlideTime + (this.changeSpeed * 1000)) - Date.now();
                if (timeRemaining <= 0){
                    this.slideImage();
                }else{
                    clearInterval(this.timerID);
                    this.timerID = setTimeout(
                        () => this.slideImage(),
                        timeRemaining
                    );
                }
                break;

            default:
                throw new Error('Unknown slider prepare stage');
        }
    }

    componentDidUpdate(){
        if (this.prepNextStage !== 0 && this.prepNextStage !== 2){
            this.prepareNext(++this.prepNextStage);
        }
    }

    nextPicLoaded(){
        if (this.prepNextStage !== 2){
            return;
        }

        // Ensures this only fires once
        if (!this.isPicLoading){
            return;
        }
        this.isPicLoading = false;
        
        this.prepareNext(++this.prepNextStage);
    }

    render() {
        if (typeof window === `undefined`) {
            return(<></>);
        }

        // Find correct picture width and height
        let screenSizeEM = Utils.getScreenWidthEM();

        let selSizeOption;
        for (const i in this.props.pictureSizeManifest) {
            if (this.props.pictureSizeManifest.hasOwnProperty(i)) {
                let picSizeOption = this.props.pictureSizeManifest[i];
                if (("maxScreenWidth" in picSizeOption) && picSizeOption.maxScreenWidth < screenSizeEM){
                    continue;
                }
                if (("minScreenWidth" in picSizeOption) && picSizeOption.minScreenWidth >= screenSizeEM){
                    continue;
                }

                if (selSizeOption === undefined || selSizeOption.picHeight < picSizeOption.picHeight){
                    selSizeOption = picSizeOption;
                }
            }
        }
        if (selSizeOption === undefined){
            throw new Error('No suitable slide picture width found');
        }

        // Compile style
        let slideContStyle = {
            width: (selSizeOption.picWidth * 2) + "em",
            height: selSizeOption.picHeight + "em"
        }
        if (this.state.translate){
            slideContStyle.transform = "translateX(" + (-1 * selSizeOption.picWidth) + "em)";
        }else{
            slideContStyle.transform = "translateX(0em)";
        }

        // Compile class name
        let slideContClass = 'slideContainer';
        if (this.state.disableAni){
            slideContClass = slideContClass + " disableAni";
        }

        // Compile pictures
        let nextInd = this.state.picInd + 1;
        if (nextInd === this.numImages){
            nextInd = 0;
        }

        // Compile slides
        let slides = [];
        let maskStyle = {};
        if (selSizeOption.maskHeight){
            maskStyle.height = selSizeOption.maskHeight + "em";
        }else{
            maskStyle.height = selSizeOption.picHeight + "em";
        }
        if (selSizeOption.maskWidth){
            maskStyle.width = selSizeOption.maskWidth + "em";
        }else{
            maskStyle.width = selSizeOption.picWidth + "em";
        }

        let slideStyle = {
            height: selSizeOption.picHeight + "em",
            width: selSizeOption.picWidth + "em"
        };

        slides.push(
            <div className='slideImage' key={this.state.picInd} style={slideStyle}>
                <Img fixed={this.pictureManifest[this.state.picInd][selSizeOption.imageKey].childImageSharp.fixed} 
                        alt={this.pictureManifest[this.state.picInd].caption} 
                        style={slideStyle} />
            </div>
        );
        slides.push(
            <div className='slideImage' key={nextInd} style={slideStyle}>
                <Img fixed={this.pictureManifest[nextInd][selSizeOption.imageKey].childImageSharp.fixed} 
                        alt={this.pictureManifest[nextInd].caption} 
                        style={slideStyle}
                        onLoad={this.nextPicLoaded()} 
                        />
            </div>
        );

        return (
            <div className='slideContainerMask' style={maskStyle}>
                <div className={slideContClass} style={slideContStyle}>
                    {slides}
                </div>
            </div>
        );
    }
}
 
export default PictureSlider;