import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import style from './style.module.scss';

import IndyLink from 'components/IndyLink';

export function SBRacingSponsor({backgroundImage}){
    return (
        <BackgroundImage className={style.wrap} fluid={backgroundImage.childImageSharp.fluid}>
            <h1 className={style.title}>Mashoom is sponsoring Will Dendy, racing for SEAT Sport UK</h1>

            <div className={style.content}>
                <div className={style.contSect}>
                    <p>Mashoom was born out of motor sport and we are really excited to be sponsoring Will Dendy for 2020, across his racing for SEAT Sport UK as well as his simulator racing and commentating.</p><br></br>
                    <p><IndyLink adr="/racing">Click here</IndyLink> to read some more details of this partnership and the exciting events we plan to offer our customers and clients.</p>
                </div>
            </div>
        </BackgroundImage>
    );
}