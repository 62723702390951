import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';

import './index.scss';

import SlideBanner from 'components/SlideBanner';
import { SBStudentTeams } from 'components/SlideBanner/StudentTeams';
import { SBRacingSponsor } from 'components/SlideBanner/RacingSponsor';
import { HomeSellPoints } from 'components/SellPoints';
import { WorkedWith } from 'components/WorkedWith';
import HomeMainInfo from 'components/HomeMainInfo';
import BackgroundImage from 'gatsby-background-image';
import CallsToAction from 'components/CallsToAction';

export default function IndexPage(){
    let images = getImages();

    return (
        <PageWrap
            title="Mashoom"
            path="/"
        >
            <Section margin='none'>
                <HomeMainInfo></HomeMainInfo>
            </Section>

            <BackgroundImage id='HomeBusinessCase' fluid={images.banner_businessCase.childImageSharp.fluid}> 
                <div>
                    <h2>Join the 1000s saving time, money and their sanity</h2>

                    <div>
                        <p>Whether it's being able to generate a report in seconds rather than days or preventing two versions of one file being edited at the same time, a good data system can improve every area of your operations.</p>
                        <p>We are here to show you what is possible.</p>
                        <p>We can either work with you to create what doesn't exist, or show you the solution we have already created.</p>
                    </div>
                </div>
            </BackgroundImage>

            <Section id='HomePlatformBuild' margin='none'>
                <div id='HPB_LeftSect'>
                    <div>
                        <h3>Ready to go Solutions</h3>
                        <p>Our modules are designed to fulfil a specific workflow, task or use case. However they are flexible and can be setup to fulfil a broad range of requirements.</p><br />
                        <p>Each module is independent, but seamlessly integrate with others.</p><br />

                        <p>Our modules are currently being used to:</p>
                        <ul>
                            <li>Distribute, receive and manage tendering documents</li>
                            <li>Manage and control CAD designs</li>
                            <li>Automate auditing processes and analyse the collected data</li>
                            <li>Book, count, authorise and manage holiday bookings</li>
                            <li>Automate employees "check-in" daily, specifying if they are in the office or not</li>
                            <li>Much, much more...</li>
                        </ul>
                    </div>
                </div>
                <div id="vertSep"></div>
                <div id='HPB_RightSect'>
                    <div>
                        <h3>The Accelerated Development Program</h3>
                        <p>We work with our customers to understand their problem, then build a solution on our platform.</p>
                        <br />
                        <p>Bespoke software development is both expensive, then it requires maintenance; Mashoom offers a new alternative.</p>
                        <br />
                        <p>Our whole platform has been built using this method. We go to great lengths to make sure we design the best tool for the job, then keep in close contact through deployment and beyond to make sure every need is covered.</p>

                        <CallsToAction contClassName="ADP_CTA" types={['ContactUs']}></CallsToAction>
                    </div>
                </div>
                
            </Section>

            <Section id='HomeSellPoints'>
                <HomeSellPoints></HomeSellPoints>
            </Section>

            <Section id='HomeTopSlider' margin="none">
                <SlideBanner>
                    <SBStudentTeams backgroundImage={images.banner_studentTeams}/>
                    <SBRacingSponsor backgroundImage={images.banner_racingSponsor}/>
                </SlideBanner>
            </Section>

            <Section id='HomeWorkedWith'>
                <WorkedWith></WorkedWith>
            </Section>
        </PageWrap>
    );
}

// https://www.lbsi.com/wp-content/uploads/2015/09/ERP-MakeLifeEasier.pdf

// https://unsplash.com/photos/rMmibFe4czY
// Photo by Jeremy Thomas on Unsplash

//https://unsplash.com/photos/AT77Q0Njnt0

function getImages() {
    return useStaticQuery(graphql`
        query HomePageImages {
            banner_intro: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "HomeBannerBackgrounds/CodeBackground.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            banner_studentTeams: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "HomeBannerBackgrounds/carBackground.png"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            banner_racingSponsor: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/racing/WillDendyInCar.jpeg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            banner_businessCase: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "pages/home/ChartOnDesk.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
}